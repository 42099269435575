import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import { KIFFGO } from '../../library/kiffgo';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const SameDayDeliveryPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="24/7 Affordable Man & Van - Guaranteed to beat any price | Get a quote in 4 seconds" keywords={[`24/7 Man and Van`, `best price man and van london`, `Get a quick quote man and van`, `cheap man and van london`, `reliable man and van`, `man with a van`, `man in a van`]} />
    <ServiceHeroContainer
      jobName={'Man and van'}
      title={'24/7 Affordable Man & Van'}
      description={'We can be there within 1 hour - guaranteed to beat any price. Get a quote in 4 seconds'}
      heroImg={require('../../assets/services-heroes/cupboard.jpg')}
      reviewImage={require('../../assets/avatars/19.png')}
      reviewContent={'Gamechanger - Trustworthy and User friendly service. For a new app in this space they deserve credits for making the process much more user centered. I was able to book the service while I waited in coffeeshop, since few of the same services are mobile friendly. Booking was easy and fast. The driver sent messages a few times to clarify times so all worked out smooth enough. Communication with them worked fine and I felt in good hands. It’s important to be specific about time you need as they likely have another job after though, which I imagine could be stressful for both sides.'}
      reviewName={'Joachim'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Reliable & Affordable Man & Van'}
      blurb={'The most affordable man and van in London - guaranteed reliability and great price. Book over the phone, website or app - 24/7 customer support.'}
      
      blurb3={'JJD is quickly becoming the UK\'s favourite man with a van service. We started in London and Greater London, but we are rapidly expanding across the UK - bringing customers an Uber for vans at great prices and availability. We can be at your pickup location within 1 hour.'}
    />\
    <ServicesGallery
      gallerySubtitle={'We do house moves, flat moves, furniture lugging, and more everyday'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about same-day deliveries?'}
      faqs={[
        {
          question: 'How much does it cost?',
          answer: 'About the same as an UberXL for most jobs, depending on your journey and van size. Just book online - and see an instant price before you book.'
        },
        {
          question: 'How soon can you be at my pickup location?',
          answer: 'We can be there within 1 hour. Just set an arrival date and time when you book.'
        },
        {
          question: 'Do I get all the space in the van?',
          answer: 'Yes, and we have 3 van sizes available: Medium, Long (Large) and Luton (XL).'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default SameDayDeliveryPage;
